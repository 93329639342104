import React, { useEffect, useState } from 'react';
import { constant } from '../../utils/constant';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const CategoryCard = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const fetchCategoryData = async () => {
    try {
      const response = await fetch(
        `${constant.CONST_SARCASM_BLOG_BY_CATEGORY}/${id}`
      );
      const json = await response.json();
      if (json.success) {
        setCategoryData(json.data);
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllCategory = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_CATEGORY);
      const result = await response.json();
      if (result.success) {
        setAllCategory(result.data);
      }
    } catch (error) {
      console.error('Error fetching all categories:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCategoryData();
    fetchAllCategory();
  }, [id]);

  const getCategoryName = (categoryId) => {
    const category = allCategory.find((cat) => cat._id === categoryId);
    return category ? category.name : 'Unknown Category';
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <Spinner />
      </div>
    );
  }

  if (!isLoading && categoryData.length === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <p>No Data Available</p>
      </div>
    );
  }

  return (
    <div style={{ margin: '1rem 0', padding: '2rem ' }}>
      <div className="row g-3">
        {categoryData.map((item) => (
          <div className="col-lg-4" key={item?._id}>
            <div className="h-100 post-box-layout4 box-border-dark-1 radius-default padding-20 bg-color-old-lace box-shadow-large shadow-style-2 transition-default">
              <div className="figure-holder radius-default">
                <Link to={`/blog/${item._id}`} className="link-wrap">
                  <img
                    className="object-fit-cover w-100"
                    height="250"
                    src={item?.bannerImageUrl}
                    alt="Post"
                  />
                </Link>
              </div>
              <div className="content-holder">
                <div className="entry-category style-2 color-dark-1-fixed">
                  <ul>
                    <li>
                      <Link to={`/blog/${item._id}`}>
                        {getCategoryName(item?.blogCategoryId)}
                      </Link>
                    </li>
                  </ul>
                </div>
                <h3 className="entry-title color-dark-1-fixed underline-animation">
                  <Link to={`/blog/${item._id}`} className="link-wrap">
                    {item?.title}
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryCard;
