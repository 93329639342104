import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../../app.css';
import logoPng from '../../assets/logo.png';
import MobileMenu from './MobileMenu';
import HeaderActions from './HeaderActions';
import { Link } from 'react-router-dom';
import { constant } from '../../utils/constant';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import CategoryIcon from '@mui/icons-material/Category';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HomeIcon from '@mui/icons-material/Home';

const Header = ({ handleSideBar }) => {
  const [theme, setTheme] = useState('light');
  const [isSticky, setIsSticky] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [topics, setTopics] = useState([]);
  const menuRef = useRef(null);
  const stickyPlaceholderRef = useRef(null);

  // Toggle the drawer state
  const toggleDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  // Toggle theme state
  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  const fetchTopics = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_CATEGORY);
      const result = await response.json();
      if (result.success) {
        setTopics(result.data);
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    const handleScroll = () => {
      const menu = menuRef.current;
      const topbarHeight =
        document.getElementById('topbar-wrap')?.offsetHeight || 0;
      const targetScroll = topbarHeight;

      if (window.scrollY > targetScroll && !isSticky) {
        setIsSticky(true);
        if (menu && stickyPlaceholderRef.current) {
          stickyPlaceholderRef.current.style.height = `${menu.offsetHeight}px`;
        }
      } else if (window.scrollY <= targetScroll && isSticky) {
        setIsSticky(false);
        if (stickyPlaceholderRef.current) {
          stickyPlaceholderRef.current.style.height = '0';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  useEffect(() => {
    fetchTopics();
  }, []);

  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      <List>
        {['Home', 'About', 'Contact'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              component={Link}
              to={text === 'Home' ? '/' : `/${text.toLowerCase()}`}
            >
              <ListItemIcon>
                {index === 0 ? (
                  <HomeIcon />
                ) : index === 1 ? (
                  <EmojiObjectsIcon />
                ) : (
                  <ContactMailIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}

        {/* Categories dropdown */}
        <ListItem disablePadding>
          <ListItemButton onClick={handleDropdownToggle}>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Categories" />
          </ListItemButton>
          {dropdownOpen && (
            <div
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                border: '1px solid #ccc',
                marginTop: '8px',
                padding: '8px 0',
                width: '100%',
                top: '100%',
                left: '10%',
              }}
            >
              <ul style={{ listStyle: 'none' }}>
                {topics &&
                  topics.map((cat, index) => (
                    <Link
                      to={`/categories/${cat._id}`}
                      onClick={() => setDropdownOpen(false)}
                      key={index}
                    >
                      <li>{cat?.name}</li>
                    </Link>
                  ))}
              </ul>
            </div>
          )}
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <header className={`header header1 sticky-on ${isSticky ? 'sticky' : ''}`}>
      <div id="topbar-wrap">
        <div className="topbar-global color-dark-1-fixed">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between gap-1">
              <div className="news-feed-wrap">
                <div
                  id="news-feed-slider"
                  className="news-feed-slider initially-none"
                >
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Underwater Exercise Is Used Weak Of Muscles Thats Good
                    </a>
                  </div>
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Tesla’s Cooking Up A New Way To Wire
                    </a>
                  </div>
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      50 Years After The Moon Landing: How Close
                    </a>
                  </div>
                  <div className="single-slide">
                    <a href="post-format-default.html" className="link-wrap">
                      Millions Of Manuscripts Are Written
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4">
                <div className="current-date d-lg-block d-none">
                  July 5, 2023
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div className="d-lg-block d-none">
                    <div className="my_switcher">
                      <ul>
                        <li title="Light Mode">
                          <button
                            type="button"
                            aria-label="Light"
                            className="setColor light"
                            data-theme="light"
                            onClick={() => toggleTheme('light')}
                          >
                            <i className="bi bi-sun"></i>
                          </button>
                        </li>
                        {/* Additional theme buttons */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sticky-placeholder" ref={stickyPlaceholderRef}></div>
      <div>
        <img
          src={constant.CONST_HAMBURGER_ICON}
          alt="humburger-icon"
          className="humburger-icon"
          onClick={() => toggleDrawer(true)}
        />
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => toggleDrawer(false)}
          sx={{
            width: '300px',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: '300px',
            },
          }}
        >
          {DrawerList}
        </Drawer>
      </div>
      <div id="navbar-wrap" ref={menuRef} className="navbar-wrap">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-md-block d-none">
              <Link
                to="/"
                className="link-wrap desktop-logo img-height-100"
                aria-label="Site Logo"
              >
                <img width="180" height="46" src={logoPng} alt="logo" />
              </Link>
            </div>
            <div className="d-md-none d-block">
              <a
                href="index.html"
                className="link-wrap mobile-logo img-height-100"
                aria-label="Site Logo"
              >
                <img width="170" height="42" src={logoPng} alt="logo" />
              </a>
            </div>
            <MobileMenu />
            <HeaderActions handleSideBar={handleSideBar} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
