import React from 'react';
// import postImg42 from '../../assets/blog/post42.webp';
// import postImg43 from '../../assets/blog/post43.webp';
// import postImg44 from '../../assets/blog/post44.webp';
// import postImg45 from '../../assets/blog/post45.webp';
import logoImg from '../../assets/logo-white.png';

const Footer = () => {
  return (
    <div>
      <footer className="footer footer1">
        {/* <div className="footer-top">
          <div className="container">
            <div className="box-bottom-border">
              <div className="section-heading">
                <h2 className="title">Follow Me On Instagram</h2>
              </div>
              <div className="instagram-wrap-layout1">
                <a
                  href="https://www.instagram.com/sarcastic_us/"
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // For security best practices
                  className="follow-btn"
                >
                  Follow Me
                  <i className="bi bi-send" />
                </a>

                <div className="row g-3">
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-scandal">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg42}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-mimosa">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg43}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-selago">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg44}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="instagram-box-layout1 box-border-light-1-fixed padding-20 pxy-xs-10 radius-default bg-color-old-lace">
                      <div className="figure-holder radius-default">
                        <a
                          target="_blank"
                          href="#"
                          className="link-wrap img-height-100"
                        >
                          <img
                            width={300}
                            height={300}
                            src={postImg45}
                            alt="Post"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-main">
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-4 col-12">
                <div className="footer-widget">
                  <div className="footer-about pe-lg-5">
                    <div className="logo-holder">
                      <a
                        href="index.html"
                        className="link-wrap img-height-100"
                        aria-label="Site Logo"
                      >
                        <img width={180} height={46} src={logoImg} alt="logo" />
                      </a>
                    </div>
                    <p className="description">
                      Expert insights, industry trends, and inspiring stories
                      that help you live and work on your own terms. Expert
                      insights, industry trends.
                    </p>
                    <p className="social-label">Follow Us</p>
                    <div className="axil-social social-layout-1 size-small gap-12">
                      <ul>
                        <li className="facebook">
                          <a
                            href="https://www.facebook.com/SarcasmLol"
                            aria-label="Learn more from SarcasmLol on Facebook"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-facebook" />
                          </a>
                        </li>
                        <li className="instagram">
                          <a
                            href="https://www.instagram.com/sarcastic_us"
                            aria-label="Learn more from SarcasmLol on Instagram"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-instagram" />
                          </a>
                        </li>
                        <li className="youtube">
                          <a
                            href="https://www.youtube.com/@Sarcasmyoutube"
                            aria-label="Learn more from SarcasmLol on YouTube"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-youtube" />
                          </a>
                        </li>
                        <li className="twitter">
                          <a
                            href="https://x.com/sarcastic_us"
                            aria-label="Learn more from SarcasmLol on Twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-twitter" />
                          </a>
                        </li>
                        <li className="threads">
                          <a
                            href="https://www.threads.net/@sarcastic_us"
                            aria-label="Learn more from SarcasmLol on Threads"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-threads" />
                          </a>
                        </li>
                        <li className="snapchat">
                          <a
                            href="https://www.snapchat.com/add/sarcasm.og"
                            aria-label="Learn more from SarcasmLol on Snapchat"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-snapchat" />
                          </a>
                        </li>
                        <li className="linkedin">
                          <a
                            href="https://www.linkedin.com/company/sarcasm-media"
                            aria-label="Learn more from SarcasmLol on LinkedIn"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-linkedin" />
                          </a>
                        </li>
                        <li className="reddit">
                          <a
                            href="https://www.reddit.com/r/Sarcasm_reddit/"
                            aria-label="Learn more from SarcasmLol on Reddit"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-reddit" />
                          </a>
                        </li>
                        <li className="whatsapp">
                          <a
                            href="https://whatsapp.com/channel/0029VapGFlT0LKZDZCaogN19"
                            aria-label="Join the SarcasmLol WhatsApp Channel"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-whatsapp" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h3 className="widget-title h3-small">About</h3>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="about.html">About</a>
                      </li>
                      <li>
                        <a href="author.html">Author</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Culture Foram</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Culture Foram</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">United Kingdom</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Media</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h3 className="widget-title h3-small">Features</h3>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="archive-layout1.html">Technology</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Politics</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Middle East</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Culture Foram</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">United Kingdom</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Features</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h3 className="widget-title h3-small">Categories</h3>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="archive-layout1.html">Business Leaders</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Markets</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Australia</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Celebrity News</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Culture Foram</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">TV News</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h3 className="widget-title h3-small">Support</h3>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Music News</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Style News</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Entertainment</a>
                      </li>
                      <li>
                        <a href="archive-layout1.html">Executive</a>
                      </li>
                      <li>
                        <a href="404.html">404</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-copyright">
            <span className="copyright-text">© 2024. All rights reserved</span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
