import React from 'react';

const HeroArea = () => {
  return (
    <div>
      <section className="hero-wrap-layout1 bg-color-light-1 transition-default">
        <div className="container">
          <div className="hero-box-layout1">
            <h1 className="title h1-large">
              Hey, We’re Sarcasm.
              <span> See Our Thoughts, Stories And Ideas.</span>
            </h1>
            {/* <form action="#" className="search-form radius-default">
              <input
                type="text"
                className="email-input"
                placeholder="Search"
              />
              <button type="submit" className="axil-btn">
                <i className="bi bi-search"></i>
              </button>
            </form> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroArea;
