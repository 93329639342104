// const baseUrl = 'http://35.225.122.157:8080'; //dev
const baseUrl = 'https://jarvis.work:8080'; //pro
export const constant = Object.freeze({
  // Other Constants
  CONST_HAMBURGER_ICON: `https://icons.veryicon.com/png/o/miscellaneous/linear-icon-45/hamburger-menu-5.png`,
  // API End Points
  CONST_SARCASM_BLOG: `${baseUrl}/api/v1/sarcasm/blog`,
  CONST_SARCASM_CONTACT_US: `${baseUrl}/api/v1/sarcasm/contact-us`,
  CONST_SARCASM_BLOG_CATEGORY: `${baseUrl}/api/v1/sarcasm/blog-category`,
  CONST_SARCASM_BLOG_BY_CATEGORY: `${baseUrl}/api/v1/sarcasm/blog-by-category`,
  CONST_SARCASM_BLOG_RECENT: `${baseUrl}/api/v1/sarcasm/recent-blogs-for-each-category`,
});
