import React from 'react';
// import profileImg from '../../assets/blog/profile4.webp';
import { Link } from 'react-router-dom';

const HeaderActions = ({ handleSideBar }) => {
  return (
    <div className="d-flex align-items-center gap-3">
      {/* <div className="d-lg-block d-none">
        <div className="search-trigger-wrap">
          <i className="bi bi-search"></i>
        </div>
      </div> */}


      <div className="d-lg-block d-none">
        <div className="profile-wrap dropdown-item-wrap">
          <div className="navbar navbar-expand-md">
            <div className="dropdown">
              <Link
                className="dropdown-toggle"
                to="/"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                aria-label="Profile"
              >
                {/* <span className="thumble-holder img-height-100">
                  <img
                    width="40"
                    height="40"
                    src={profileImg}
                    alt="Profile"
                  />
                </span> */}
              </Link>
              <div
                className="dropdown-menu dropdown-menu-end animate slideIn"
                aria-labelledby="navbarDropdown2"
              >
                <div className="dropdown-menu-inner">
                  <div className="profile-content with-icon">
                    <ul>
                      <li>
                        <a href="author.html">
                          <div className="icon-holder">
                            <i className="bi bi-person"></i>
                          </div>
                          Profile
                        </a>
                      </li>
                      <li>
                        <a href="author.html">
                          <div className="icon-holder">
                            <i className="bi bi-activity"></i>
                          </div>
                          Activity Log
                        </a>
                      </li>
                      <li>
                        <a href="author.html">
                          <div className="icon-holder">
                            <i className="bi bi-plus-square"></i>
                          </div>
                          Library
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="profile-content">
                    <ul>
                      <li>
                        <a href="author.html">Become a Member</a>
                      </li>
                      <li>
                        <a href="author.html">Apply for author verification</a>
                      </li>
                      <li>
                        <a href="author.html">Settings</a>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <a
                      href="#"
                      className="w-100 axil-btn axil-btn-ghost btn-color-alter axil-btn-small"
                    >
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-lg-none d-block">
        <div className="my_switcher">
          <ul>
            {/* <li title="Light Mode">
              <button
                type="button"
                aria-label="Light"
                className="setColor light"
                data-theme="light"
              >
                <i className="bi bi-sun"></i>
              </button>
            </li> */}
            <li title="Dark Mode">
              <button
                type="button"
                aria-label="Dark"
                className="setColor dark"
                data-theme="dark"
              >
                <i className="bi bi-moon"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="mobile-menu-btn d-lg-none d-block">
        <button
          aria-label="Offcanvas"
          className="btn-wrap"
          data-bs-toggle="offcanvas"
          data-bs-target="#mobilemenu-popup"
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  );
};

export default HeaderActions;
